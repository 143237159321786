/* global Persona */
import cableReady from 'cable_ready';
import moment from 'moment';
import ApplicationController from './application_controller';
import consumer from '../channels/consumer';
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from './utilities/loading_spinner';

export default class extends ApplicationController {
  static targets = ['help', 'submitBtn', 'firstName', 'lastName', 'dob'];

  connect() {
    super.connect();

    consumer.subscriptions.create('IdVerificationChannel', {
      received: (data) => {
        if (data.cableReady) cableReady.perform(data.operations);
      },
    });

    document.addEventListener(
      'openIdVerificationModal',
      this.verificationForm.bind(this),
    );

    document.addEventListener(
      'completeIdVerification',
      this.completeIdVerification.bind(this),
    );
  }

  submitBtnTargetConnected() {
    $('.dob_field').mask('00/00/0000');
    this.validateFormInputs();
    $('#user_first_name, #user_last_name, #user_date_of_birth').on(
      'input',
      this.validateFormInputs.bind(this),
    );

    $('#user_first_name, #user_last_name, #user_date_of_birth').on(
      'change',
      this.validateFormInputs.bind(this),
    );
  }

  validateFormInputs() {
    if (this.firstNameTarget.value.trim().length && this.lastNameTarget.value.trim().length) {
      const dob = this.dobTarget.value.trim();
      if (dob.length < 10) {
        this.disableSubmitBtn();
      } else if (this.isValidDOB(dob)) {
        this.enableSubmitBtn();
      } else {
        this.setAndReportValidity(this.dobTarget, 'Date of Birth must be a valid date between 18 and 120 years of age');
        this.disableSubmitBtn();
      }
    } else {
      this.disableSubmitBtn();
    }
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener(
      'openIdVerificationModal',
      this.verificationForm.bind(this),
    );
    document.removeEventListener(
      'completeIdVerification',
      this.completeIdVerification.bind(this),
    );
  }

  // https://docs.withpersona.com/docs/quickstart-embedded-flow
  // https://docs.withpersona.com/docs/embedded-flow-event-handling
  initPersona(event) {
    event.preventDefault();

    showLoadingSpinner();

    const client = new Persona.Client({
      templateId: 'itmpl_Gw5oZEM2Hfq9Vg8JjymnR7TP',
      referenceId: this.data.get('user-email'),
      environmentId: "env_a47EkTnpw59P9K7DStDoN8At",
      themeSetId: "theset_F4tFCaaECbyR6jdNgkfzvGm3",
      fields: {
        "brand": "Promescent"
      },
      onReady: () => client.open(),
      onCancel: () => hideLoadingSpinner(),
      onEvent: (name, { inquiryId }) => {
        if (name === 'start') {
          this.updateUserInquiryId(inquiryId);
        }
      },
      onComplete: ({ status }) => {
        if (status === 'failed') {
          $.get('/user_verifications/verification_failed');
          hideLoadingSpinner();
        }
      },
    });
  }

  updateUserInquiryId(inquiryId) {
    $.ajax({
      url: '/user_verifications',
      type: 'PUT',
      beforeSend(xhr) {
        xhr.setRequestHeader(
          'X-CSRF-Token',
          $('meta[name="csrf-token"]').attr('content'),
        );
      },
      data: `persona_inquiry_id=${inquiryId}`,
    });
  }

  needHelp() {
    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::Questionnaires::NeedHelp#show');
      },
    });
  }

  verificationForm() {
    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::Questionnaires::VerificationForm#show');
      },
    });
  }

  completeIdVerification() {
    window.location.href = '/user_verifications/complete_order';
  }

  isValidDOB(dob = '') {
    const currentYear = moment().year();
    const birthYear = moment(dob, 'MM/DD/YYYY').year();
    const age = currentYear - birthYear;
    return age >= 18 && (birthYear >= currentYear - 120);
  }

  enableSubmitBtn() {
    [this.firstNameTarget, this.lastNameTarget, this.dobTarget].forEach((input) => this.setAndReportValidity(input, ''));
    this.submitBtnTarget.classList.remove('hover:cursor-not-allowed');
    this.submitBtnTarget.removeAttribute('disabled');
  }

  disableSubmitBtn() {
    this.submitBtnTarget.classList.add('hover:cursor-not-allowed');
    this.submitBtnTarget.setAttribute('disabled', true);
  }

  setAndReportValidity(element, message = '') {
    element.setCustomValidity(message);
    element.reportValidity();
  }
}
