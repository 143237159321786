import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'noneAnswer',
    'regularAnswer',
    'commentable',
    'endResponse',
    'freeTextField',
  ];

  connect() {
    super.connect();

    const submitBtn = document.querySelector('.submit-button');

    if (submitBtn) {
      submitBtn.addEventListener('click', (event) => {
        event.preventDefault();
      });
    }

    document.addEventListener('auto-advance-to-next-question', this.submitForm);
  }

  disconnect() {
    document.removeEventListener('auto-advance-to-next-question', this.submitForm);
  }

  initialize() {
    this.checkOnLoad();
    this.shouldEnableSubForm();
    this.handleQuantitySelectionTooltips();
  }

  disableSubmit() {
    document
      .querySelector('.submit-button')
      .removeEventListener('click', this.submitForm);
  }

  enableSubmit() {
    const submitBtn = document.querySelector('.submit-button');

    if (submitBtn) {
      submitBtn.addEventListener('click', this.submitForm);
    }
  }

  submitForm() {
    document.querySelector('form').submit();
  }

  checkMultiAnswer() {
    const el = $('.multi_answer_cb');

    el.each((idx) => {
      if ($(el[idx]).prop("checked") === true) {
        $(el[idx])
          .prop("checked", true)
          .siblings("label")
          .css({ "background-color": "black", color: "#fff" })
          .parent("li")
          .css("border", "solid 2px black");

        this.activateRightArrow();
        this.enableSubmit();
        $(el[idx])
          .nextAll('.more-info:first')
          .removeClass('hidden')
          .addClass('border-solid border-2 border-blue-500');
      }
    });
  }

  checkSingleChoice() {
    const el = $('.qa-radio');

    el.each((idx) => {
      if ($(el[idx]).prop("checked") === true) {
        $(el[idx])
          .prop("checked", true)
          .siblings("label")
          .attr('style', `background-color: black !important; color: #fff;`)
          .parent("li")
          .css("border", "2px solid black");

        if ($(el[idx]).find('div')) {
          $(el[idx])
            .parent('li')
            .find('div')
            .css('color', '#FFF');
        }

        this.activateRightArrow();
        this.enableSubmit();
        $(el[idx])
          .nextAll('.more-info:first')
          .removeClass('hidden')
          .addClass('border-solid border-2 border-blue-500');
      }
    });

    this.checkDosageQuantityRadios();
  }

  checkOnLoad() {
    this.checkMultiAnswer();
    this.checkSingleChoice();
  }

  activateRightArrow() {
    if (!this.shouldEnableSectionForm()) return;

    $('.right-arrow').attr('src', $('.submit-button').data('rightarrowsubmit'));
  }

  disableEnableSurveySubmit() {
    if (window.questionnaireHardStop) return;

    if (!this.shouldEnableSectionForm()) return;

    $('.qa-radio, .multi_answer_cb, .doses-radio').each(() => {
      if (
        $('.qa-radio:checked, .multi_answer_cb:checked, .doses-radio:checked')
      ) {
        this.enableSubmit();
        this.activateRightArrow();
      } else {
        this.disableSubmit();
      }
    });
  }

  selectMultipleAnswers(event) {
    event.preventDefault();

    if (event.target.nodeName !== 'LABEL') return;

    const selectedLabel = event.target;
    const selectedInput =
      selectedLabel.parentNode.getElementsByTagName('input')[0];

    const answers = this.regularAnswerTargets;
    const noneAnswers = this.noneAnswerTargets;
    const endResponses = this.endResponseTargets;

    if (noneAnswers.includes(selectedLabel)) {
      answers.forEach((answer) => this.deselectOption(answer));
      endResponses.forEach((endResponse) => this.deselectOption(endResponse));
    }

    if (answers.includes(selectedLabel)) {
      noneAnswers.forEach((answer) => this.deselectOption(answer));
    }

    if (endResponses.includes(selectedLabel)) {
      noneAnswers.forEach((answer) => this.deselectOption(answer));
    }

    this.displayMoreInfo($(selectedInput));

    if (selectedInput.checked && !this.autoAdvanceAnswerSelected(selectedLabel)) {
      this.deselectOption(selectedLabel);
    } else {
      this.selectOption(selectedLabel);
    }

    this.checkHardStops();

    if (this.autoAdvanceAnswerSelected(selectedLabel)) {
      document.dispatchEvent(this.advanceToNextQuestionEvent);
    }
  }

  /* eslint no-param-reassign: "error" */
  selectOption(labelElement) {
    if (labelElement.nodeName === 'LI') return;
    labelElement.parentNode.getElementsByTagName('input')[0].checked = true;
    labelElement.style.color = '#fff';
    labelElement.parentNode.style.border = "solid 2px black";
    labelElement.style.backgroundColor = "black";
    labelElement.parentNode.style.color = '#000';
  }

  /* eslint no-param-reassign: "error" */
  deselectOption(labelElement) {
    if (labelElement.nodeName === 'LI') return;
    labelElement.parentNode.getElementsByTagName('input')[0].checked = false;
    labelElement.style.color = '#000';
    labelElement.parentNode.style.border = '2px solid #222';
    labelElement.style.backgroundColor = 'transparent';
  }

  toggleRadioButton(event) {
    window.questionnaireHardStop = false;

    const selected = $(event.target);
    const unselected = $(this.element)
      .find('input[type=radio]:not(checked)')
      .not(`#${selected.attr('id')}`);

    this.closeMoreInfos(event.target);

    this.displayMoreInfo(selected);

    selected
      .attr('checked', 'checked')
      .siblings('label')
      .attr('style', `background-color: black !important; color: #fff;`)
      .parent('li')
      .css('border', '2px solid black');

    if (selected.find('div')) {
      selected
        .parent('li')
        .find('div')
        .css('color', '#FFF');
    }

    unselected
      .removeAttr('checked')
      .siblings('label')
      .attr('style', '')
      .parent('li')
      .css('border', '2px solid #222');

    if (unselected.find('div')) {
      unselected
        .parent('li')
        .find('div')
        .css('color', '#71717A');
    }
  }

  toggleMultipleRadioButton(event) {
    const selected = $(event.target);
    const unselected = selected
      .closest('radiogroup')
      .find('input[type=radio]:not(checked)')
      .not(`#${selected.attr('id')}`);

    selected
      .attr('checked', 'checked')
      .siblings('label')
      .css({ "background-color": "black", color: "#fff" })
      .parent("li")
      .css("border", "2px solid black");
    unselected
      .removeAttr('checked')
      .siblings('label')
      .attr('style', '')
      .parent('li')
      .css('border', '2px solid #222');
  }

  // Hide/show more info area for answer option
  toggleUserAnswerFreeText(event) {
    const noneAnswer = $(event.target).data('none-answer');

    if (noneAnswer) {
      $(this.commentableTarget).css('display', 'none');
      return;
    }

    $(this.commentableTarget).toggle('hidden');
  }

  hardStop(event) {
    const selectedLabel = event.target;

    const endResponse =
      selectedLabel.parentNode.getElementsByTagName('input')[0];

    if (endResponse.checked) {
      window.questionnaireHardStop = true;

      this.disableSubmit();

      this.callStimulusAction({
        cb: () => {
          this.stimulate('Store::Questionnaires::HardStop#show');
        },
      });
    } else {
      this.deselectOption(selectedLabel);
    }
  }

  checkHardStops() {
    const endResponses = this.endResponseTargets;

    const hasHardStop = endResponses.some(
      (endResponse) => endResponse.previousElementSibling.checked,
    );

    if (!hasHardStop) {
      window.questionnaireHardStop = false;
    }
  }

  checkDosageQuantityRadios() {
    const dosages = $("input[name$='variant_id']");

    dosages.each((idx) => {
      if ($(dosages[idx]).prop('checked')) {
        this.activateRightArrow();
        this.enableSubmit();
      }
    });

    const quantities = $('.doses-radio');

    quantities.each((idx) => {
      if ($(quantities[idx]).prop('checked')) {
        this.activateRightArrow();
        this.enableSubmit();
      }
    });
  }

  freeTextFieldTargetConnected() {
    this.activateRightArrow();
    this.enableSubmit();
  }

  displayMoreInfo(input) {
    const hasMoreInfo = input.parent('li').data('needMoreInfo');

    if (hasMoreInfo) {
      $(input).nextAll('.more-info:first').toggle('hidden');
    }
  }

  closeMoreInfos(clickEl) {
    const inputs = $('.qa-radio');

    inputs.each(function () {
      if (clickEl === this) return;
      $(this).nextAll('.more-info:first').css('display', 'none');
    });
  }

  shouldEnableSectionForm() {
    const sectionId = $('#user_answer_form_section_id');

    if (!sectionId.length) return true;

    const qaRadioChecked = $('.qa-radio:checked').length;

    const questionIds = $('#user_answer_form_question_ids')
      .val()
      .split(' ').length;

    return qaRadioChecked === questionIds;
  }

  shouldEnableSubForm() {
    const qaRadioChecked = $('.radio-lg:checked').length;

    if (qaRadioChecked) {
      this.activateRightArrow();
      this.enableSubmit();
    }
  }

  get advanceToNextQuestionEvent() {
    return new CustomEvent('auto-advance-to-next-question');
  }

  autoAdvanceAnswerSelected(element) {
    return element.dataset.autoAdvance === 'true';
  }

  toggleSubscriptionSelectionCheckMarks(e) {
    const { checkWhiteSrc } = this.element.dataset;
    const { checkDarkSrc } = this.element.dataset;

    // White check marks for selected sub type
    e.currentTarget.querySelectorAll('img').forEach((img) => $(img).attr('src', checkWhiteSrc));

    // Black check marks for non-selected sub types
    document.querySelectorAll('input[type=radio]:not(:checked)').forEach((input) => {
      const imgs = input.parentElement.querySelectorAll('img');
      imgs.forEach((img) => $(img).attr('src', checkDarkSrc));
    });
  }

  handleQuantitySelectionTooltips() {
    if (this.element.dataset.step !== 'quantity_selection') return;

    const isQuarterly = this.element.dataset.isQuarterly === 'true';
    const packsPerShipment = isQuarterly ? [18, 36, 54, 72] : [1, 2, 3, 4];

    packsPerShipment.forEach((num) => {
      const experiences = isQuarterly === true ? num : num * 6;
      const days = isQuarterly === true ? 90 : 30;
      const $tooltip = $(`#${num}-packs-per-shipment-tooltip`);

      $tooltip.tooltipster({
        theme: 'your-company',
        position: window.matchMedia('(max-width: 767px)').matches ? 'top' : 'right',
        content: $(`<div style='padding: 10px;'><div style='width: 90%;'><strong>${experiences} experiences over ${days} days</strong></div></div></div>`),
        trigger: 'custom',
        triggerOpen: {
          mouseenter: true,
          touchstart: true,
        },
        triggerClose: {
          mouseleave: true,
          tap: true,
        },
      });
    });

    $('.not-available-tooltip').tooltipster({
      theme: 'your-company',
      position: window.matchMedia('(max-width: 767px)').matches ? 'top' : 'right',
      content: $("<div style='padding: 10px;'><p style='font-weight: bold;'>Recommended to have the best experiences </br> in one month.</p></div>"),
      contentCloning: true,
      trigger: 'custom',
      triggerOpen: {
        mouseenter: true,
        touchstart: true,
      },
      triggerClose: {
        mouseleave: true,
        tap: true,
      },
    });
  }
}
